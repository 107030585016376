import React from 'react'
import ImageBackground from 'gatsby-background-image'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import ButtonDefault from 'components/elements/ButtonDefault'

const StyledExperience = styled.div`
  margin-bottom: 100px;
  padding-bottom: 150px;
  padding-top: 100px;

  @media screen and (max-width: 991px) {
    padding-bottom: 75px;
  }
`

const Content = styled.div`
  h2 {
    padding-bottom: 15px;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  p {
    width: 78%;

    @media screen and (max-width: 991px) {
      width: 100%;
      text-align: center;
    }
  }
`

const Points = styled.div`
  p {
    font-size: ${(props) => props.theme.font.size.l};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }
`

const StyledBackground = styled(ImageBackground)`
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute !important;
  top: 0;
  opacity: 1 !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.6) 20%,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 1) 100%
  );
`

const Corner = styled.div`
  position: absolute;

  ${(props) =>
    props.position !== 'center' &&
    css`
      width: 77px;
      height: 77px;
    `}

  ${(props) =>
    props.position &&
    {
      topLeft: css`
        border-left: 2px solid rgba(0, 0, 0, 0.2);
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        left: 60px;
        top: -50px;

        @media screen and (max-width: 767px) {
          left: 30px;
        }

        @media screen and (max-width: 575px) {
          left: 10px;
        }
      `,
      bottomLeft: css`
        border-left: 2px solid rgba(0, 0, 0, 0.2);
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        left: 60px;
        bottom: 70px;

        @media screen and (max-width: 767px) {
          left: 30px;
        }

        @media screen and (max-width: 575px) {
          bottom: 10px;
          left: 10px;
        }
      `,
      topRight: css`
        border-right: 2px solid rgba(0, 0, 0, 0.2);
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        right: 60px;
        top: -50px;

        @media screen and (max-width: 767px) {
          right: 30px;
        }

        @media screen and (max-width: 575px) {
          right: 10px;
        }
      `,
      bottomRight: css`
        border-right: 2px solid rgba(0, 0, 0, 0.2);
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        right: 60px;
        bottom: 70px;

        @media screen and (max-width: 767px) {
          right: 30px;
        }

        @media screen and (max-width: 575px) {
          bottom: 10px;
          right: 10px;
        }
      `,
      center: css`
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 160px;
        width: 35px;
        height: 35px;

        @media screen and (max-width: 1199px) {
          top: 185px;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 2px;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          width: 100%;
          height: 2px;
          background: rgba(0, 0, 0, 0.2);
        }
      `,
    }[props.position]}
`

const Focus = styled.div`
  position: absolute;
  right: -55px;
  transform: rotate(270deg);
  top: 140px;

  @media screen and (max-width: 1199px) {
    top: 160px;
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.xxxl};
    font-weight: ${(props) => props.theme.font.weight.xl};
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.2);
  }
`

const Experience = ({ className, content }) => {
  return (
    <StyledExperience
      className={`position-relative mt-5 ${className ? `${className}` : ``}`}
    >
      <StyledBackground
        loading="eager"
        fadeIn={false}
        alt=""
        fluid={content.image.localFile.childImageSharp.fluid}
      />
      <div className="container pt-5">
        <div className="row position-relative py-5">
          <Corner position="topLeft" />
          <Corner position="bottomLeft" />
          <Corner position="topRight" />
          <Corner position="bottomRight" />
          <Corner className="d-lg-block d-none" position="center" />
          <Focus className="d-lg-block d-none">
            <h2>FOCUS</h2>
          </Focus>
          <div className="col-lg-6">
            <Content>{parse(content.description)}</Content>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <Points className="d-lg-block d-flex">
              {content.point.map((p, index) => (
                <p key={index} className="font-weight-xl mr-lg-0 mr-3">
                  {p.text}
                </p>
              ))}
            </Points>
          </div>
          <div className="col-12 d-flex justify-content-center pt-md-5">
            <ButtonDefault className="mt-4" icon="arrow" to={content.link.url}>
              <p>{content.link.title}</p>
            </ButtonDefault>
          </div>
        </div>
      </div>
    </StyledExperience>
  )
}

export default Experience
