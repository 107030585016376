import React, { useState, useLayoutEffect } from 'react'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'
import ParseContent from 'components/shared/ParseContent'

const StyledShadowBox = styled.div`
  position: relative;
  width: 522px;
  /* height: 452px; */

  @media screen and (max-width: 576px) {
    height: unset;
  }
`

const Box = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.light};
  height: 100%;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding: 60px;

  @media screen and (max-width: 484px) {
    padding: 30px;
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.xml};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-bottom: 30px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
  }
`

const Shadow = styled(motion.div)`
  background: ${(props) => props.theme.color.gradient.main};
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 20px;
`

const ShadowBox = ({ delay, className, content, extraInfo, reverse }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.5,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledShadowBox
      ref={ref}
      className={`mx-lg-0 mx-5 ${className ? `${className}` : ``}`}
    >
      <Shadow
        // initial={{ left: 0, top: 0 }}
        animate={
          isVisible
            ? reverse
              ? { right: -50, top: -50 }
              : { right: 25, top: 25 }
            : {}
        }
        transition={{ delay: delay || 0, type: 'spring', duration: 0.4 }}
        className="d-lg-block d-none"
      />
      <Shadow
        initial={{ left: 0, top: 0 }}
        animate={isVisible ? { left: -25, top: -25 } : {}}
        transition={{ delay: delay || 0, type: 'spring', duration: 0.4 }}
        className="d-lg-none d-block"
      />
      <Box className="d-flex align-items-center">
        <div>
          {content.title_sidebar && <h2>{content.title_sidebar}</h2>}
          {!content.title_sidebar && (
            <ParseContent content={content.description} />
          )}
          {extraInfo && extraInfo.date && (
            <div className="d-flex align-items-center mb-3 pb-1">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34 17C34 26.3885 26.3885 34 17 34C7.61153 34 0 26.3885 0 17C0 7.61153 7.61153 0 17 0C26.3885 0 34 7.61153 34 17Z"
                  fill="#3B5998"
                />
                <g clip-path="url(#clip0_306_21)">
                  <path
                    d="M7 22.8334C7.00132 23.938 7.44073 24.9971 8.22185 25.7782C9.00296 26.5593 10.062 26.9987 11.1667 27H22.8333C23.938 26.9987 24.997 26.5593 25.7782 25.7782C26.5593 24.9971 26.9987 23.938 27 22.8334V15.3334H7V22.8334ZM21.1667 19.0834C21.4139 19.0834 21.6556 19.1567 21.8611 19.294C22.0667 19.4314 22.2269 19.6266 22.3215 19.855C22.4161 20.0834 22.4409 20.3348 22.3926 20.5772C22.3444 20.8197 22.2254 21.0424 22.0505 21.2173C21.8757 21.3921 21.653 21.5111 21.4105 21.5594C21.1681 21.6076 20.9167 21.5828 20.6883 21.4882C20.4599 21.3936 20.2647 21.2334 20.1273 21.0278C19.99 20.8223 19.9167 20.5806 19.9167 20.3334C19.9167 20.0019 20.0484 19.6839 20.2828 19.4495C20.5172 19.2151 20.8351 19.0834 21.1667 19.0834ZM17 19.0834C17.2472 19.0834 17.4889 19.1567 17.6945 19.294C17.9 19.4314 18.0602 19.6266 18.1548 19.855C18.2495 20.0834 18.2742 20.3348 18.226 20.5772C18.1777 20.8197 18.0587 21.0424 17.8839 21.2173C17.7091 21.3921 17.4863 21.5111 17.2439 21.5594C17.0014 21.6076 16.7501 21.5828 16.5216 21.4882C16.2932 21.3936 16.098 21.2334 15.9607 21.0278C15.8233 20.8223 15.75 20.5806 15.75 20.3334C15.75 20.0019 15.8817 19.6839 16.1161 19.4495C16.3505 19.2151 16.6685 19.0834 17 19.0834ZM12.8333 19.0834C13.0806 19.0834 13.3222 19.1567 13.5278 19.294C13.7334 19.4314 13.8936 19.6266 13.9882 19.855C14.0828 20.0834 14.1075 20.3348 14.0593 20.5772C14.0111 20.8197 13.892 21.0424 13.7172 21.2173C13.5424 21.3921 13.3197 21.5111 13.0772 21.5594C12.8347 21.6076 12.5834 21.5828 12.355 21.4882C12.1266 21.3936 11.9313 21.2334 11.794 21.0278C11.6566 20.8223 11.5833 20.5806 11.5833 20.3334C11.5833 20.0019 11.715 19.6839 11.9494 19.4495C12.1839 19.2151 12.5018 19.0834 12.8333 19.0834Z"
                    fill="white"
                  />
                  <path
                    d="M22.8333 8.66667H22V7.83333C22 7.61232 21.9122 7.40036 21.7559 7.24408C21.5996 7.0878 21.3877 7 21.1667 7C20.9457 7 20.7337 7.0878 20.5774 7.24408C20.4211 7.40036 20.3333 7.61232 20.3333 7.83333V8.66667H13.6667V7.83333C13.6667 7.61232 13.5789 7.40036 13.4226 7.24408C13.2663 7.0878 13.0543 7 12.8333 7C12.6123 7 12.4004 7.0878 12.2441 7.24408C12.0878 7.40036 12 7.61232 12 7.83333V8.66667H11.1667C10.062 8.66799 9.00296 9.1074 8.22185 9.88852C7.44073 10.6696 7.00132 11.7287 7 12.8333L7 13.6667H27V12.8333C26.9987 11.7287 26.5593 10.6696 25.7782 9.88852C24.997 9.1074 23.938 8.66799 22.8333 8.66667Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_306_21">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(7 7)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="mb-0 ml-2">{extraInfo.date}</p>
            </div>
          )}
          {extraInfo && extraInfo.time && (
            <div className="d-flex align-items-center mb-3 pb-1">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34 17C34 26.3885 26.3885 34 17 34C7.61153 34 0 26.3885 0 17C0 7.61153 7.61153 0 17 0C26.3885 0 34 7.61153 34 17Z"
                  fill="#3B5998"
                />
                <g clip-path="url(#clip0_306_22)">
                  <path
                    d="M17 7C15.0222 7 13.0888 7.58649 11.4443 8.6853C9.79981 9.78412 8.51809 11.3459 7.76121 13.1732C7.00433 15.0004 6.8063 17.0111 7.19215 18.9509C7.578 20.8907 8.53041 22.6725 9.92894 24.0711C11.3275 25.4696 13.1093 26.422 15.0491 26.8079C16.9889 27.1937 18.9996 26.9957 20.8268 26.2388C22.6541 25.4819 24.2159 24.2002 25.3147 22.5557C26.4135 20.9112 27 18.9778 27 17C27 14.3478 25.9464 11.8043 24.0711 9.92893C22.1957 8.05357 19.6522 7 17 7V7ZM17.8333 17.3608L13.6225 20L12.7383 18.5833L16.1667 16.4375V12H17.8333V17.3608Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_306_22">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(7 7)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="mb-0 ml-2">{extraInfo.time}</p>
            </div>
          )}
          {extraInfo && extraInfo.location && (
            <div className="d-flex align-items-center mb-3">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34 17C34 26.3885 26.3885 34 17 34C7.61153 34 0 26.3885 0 17C0 7.61153 7.61153 0 17 0C26.3885 0 34 7.61153 34 17Z"
                  fill="#3B5998"
                />
                <g clip-path="url(#clip0_306_23)">
                  <path
                    d="M17 7.0354C14.7949 7.03783 12.6809 7.9148 11.1216 9.47392C9.56232 11.0331 8.68514 13.147 8.6825 15.3521C8.6825 17.4937 10.3408 20.8454 13.6117 25.3137C14.001 25.8472 14.5109 26.2811 15.0997 26.5803C15.6885 26.8795 16.3396 27.0354 17 27.0354C17.6604 27.0354 18.3115 26.8795 18.9003 26.5803C19.4891 26.2811 19.9989 25.8472 20.3883 25.3137C23.6592 20.8454 25.3175 17.4937 25.3175 15.3521C25.3148 13.147 24.4377 11.0331 22.8784 9.47392C21.3191 7.9148 19.205 7.03783 17 7.0354ZM17 18.6671C16.3407 18.6671 15.6963 18.4716 15.1481 18.1053C14.5999 17.739 14.1727 17.2184 13.9204 16.6093C13.6681 16.0003 13.6021 15.33 13.7307 14.6834C13.8593 14.0368 14.1768 13.4429 14.643 12.9767C15.1091 12.5105 15.7031 12.1931 16.3497 12.0644C16.9963 11.9358 17.6665 12.0018 18.2756 12.2541C18.8847 12.5064 19.4053 12.9337 19.7716 13.4818C20.1378 14.03 20.3333 14.6745 20.3333 15.3337C20.3333 16.2178 19.9821 17.0656 19.357 17.6908C18.7319 18.3159 17.8841 18.6671 17 18.6671Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_306_23">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(7 7)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="mb-0 ml-2">{extraInfo.location}</p>
            </div>
          )}
        </div>
      </Box>
    </StyledShadowBox>
  )
}

export default ShadowBox
