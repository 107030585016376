import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ButtonSlide from 'components/elements/ButtonSlide'

// Third Party
import styled from 'styled-components'

const StyledBrancheLinks = styled.div``

const Branch = styled.div``

const BrancheLinks = ({ className }) => {
  const {
    branches: {
      edges: branches
    }
  } = useStaticQuery(graphql`{
    branches: allWordpressPage(filter: {parent_element: {wordpress_id: {eq: 112}}}, sort: {fields: date}) {
      edges {
        node {
          title
          wordpress_id
          path
        }
      }
    }
  }`)
  return (
    <StyledBrancheLinks className={`pr-lg-5 ${className ? `${className}` : ``}`}>
      {branches.map(({ node: branch }, index) => (
        <Branch key={branch.wordpress_id} className="d-flex justify-content-end">
          <ButtonSlide icon="arrow" delay={index * 0.2} className="mb-4" to={branch.path}>
            {branch.title}
          </ButtonSlide>
        </Branch>
      ))}
    </StyledBrancheLinks>
  )
}

export default BrancheLinks