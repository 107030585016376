/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Home from 'templates/home'

export const pageQuery = graphql`
  {
    page: wordpressPage(wordpress_id: {eq: 2}) {
      ...homePageFragment
    }
  }
`

// eslint-disable-next-line react/jsx-props-no-spreading
const HomeTemplate = data => <Home {...data} />

export default HomeTemplate