/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

// Components
import ButtonShell from 'components/elements/ButtonShell'

// Images
import ArrowButton from 'img/icon/arrow_button.inline.svg'
// import UserButton from 'img/icon/user_button.inline.svg'
import userButton from 'img/icon/user_button.svg'

const IconWrapper = styled(motion.div)`
  position: absolute;
  top: 9px;
  left: 9px;
  pointer-events: none;
  height: 37px;
  width: 37px;
`

const StyledArrowButton = styled(ArrowButton)`
  height: 100%;
  width: 100%;
`

const StyledButton = styled(motion.div)`
  position: relative;
  border-radius: 29.5px;
  display: inline-block;
  height: 55px;
  width: 303px;
  
  a, button {
    color: ${props => props.theme.color.text.main};
    font-weight: ${props => props.theme.font.weight.xl};
    padding: 0 70px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.text.light};
    }
  }
`

const BackgroundSlide = styled(motion.div)`
  background: ${props=> props.theme.color.gradient.main};
  width: 0;
  height: calc(100% + 2px);
  position: absolute;
  left: -1px;
  top: -1px;
  z-index: 3;
  pointer-events: none;
  border-radius: 29.5px;
`

const ButtonWrapper = styled.div`
  overflow: hidden;
  padding: 2px;
  border-radius: 29.5px;
  &:hover {
    svg {
      path {
        stroke: ${props => props.theme.color.text.light};
        fill: ${props => props.theme.color.text.light};
      }
    }
  }
`

const BackgroundShadow = styled(motion.div)`
  position: absolute;
  background: ${props => props.theme.color.gradient.main};
  z-index: 1;
  border-radius: 29.5px;
`

const ButtonInner = styled(motion.div)`
  position: absolute;
  background: ${props => props.theme.color.face.light};
  z-index: 2;
  border-radius: 29.5px;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  z-index: 4;
  height: 100%;
  width: 100%;
`

const ButtonSlide = ({ delay, icon, isAnchor, isCustom, to, children, className }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.01,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <ButtonWrapper className={`${className ? `${className}` : ``}`}>
      <StyledButton
        ref={ref}
        initial='rest'
        whileHover='hover'
        animate={isVisible || view ? "init" : "rest"}
        className='d-flex align-items-center'
        variants={{
          init: { y: 0, transition: { delay: delay || 0, type: 'spring', duration: .6 } },
          rest: { y: 10},
          hover: { border: 'none', transition: { duration: .4 } }
        }}
      >
        <ButtonInner />
        <Content 
          className="d-flex align-items-center"
        >
          {{
            'arrow': <IconWrapper><StyledArrowButton /></IconWrapper>,
            'user': <IconWrapper><img src={userButton} alt="" /></IconWrapper>
          }[icon]}
          {isCustom ? children : (
            <ButtonShell to={to} isAnchor={isAnchor}>
              {children}
            </ButtonShell>
          )}
        </Content>
        <BackgroundShadow 
          variants={{
            rest: {
              height: '59px',
              width: '307px',
              left: '-2px',
              top: '-2px'
            }
          }}
        />
        <BackgroundSlide 
          variants={{
            hover: { width: 'calc(100% + 2px)', transition: { duration: .8, type: 'inertia' } },
          }}
        />
      </StyledButton>
    </ButtonWrapper>
  )
}

export default ButtonSlide