import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import styled from 'styled-components'

// Components
import TitleDefault from 'components/elements/TitleDefault'
import GravityForm from 'components/GravityForm'
import Image from 'components/elements/Image'
import CustomLink from '../shared/CustomLink'

const StyledButton = styled.button`
  position: relative;
  background: ${(props) => props.theme.color.gradient.main};
  border-radius: 29.5px;
  display: inline-block;
  height: 45px;
  color: ${(props) => props.theme.color.text.light};
  font-weight: ${(props) => props.theme.font.weight.xl};
  width: 200px;
`

const CloseButton = styled.button`
  z-index: 9;
  right: 0;
`

const StyledModal = styled(Modal)`
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background: ${(props) => props.theme.color.text.dark};

  @media (max-width: 991px){
    width: 100%;
    max-width: 300px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
`

function ModalVacancies({ image }) {
  const [modalIsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      {
        !sessionStorage.getItem('hide') && (
          setIsOpen(true)
        )
      }
    }, 2500)
  }, [])

  function closeModal() {
    sessionStorage.setItem('hide', 'true');
    setIsOpen(false)
  }

  return (
    <div>
      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="position-relative" onClick={closeModal}>
          <CloseButton onClick={closeModal} className="text-white p-3 position-absolute top-0">
            X
          </CloseButton>
          <CustomLink to="/vacatures/" >
            <StyledImage src={image} />
          </CustomLink>
        </div>
      </StyledModal>
    </div>
  )
}

export default ModalVacancies
