/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Images
import Calendar from 'img/icon/calendar.inline.svg'
import Eyeglasses from 'img/icon/eyeglasses.inline.svg'
import ArrowButton from 'img/icon/arrow_button.inline.svg'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledNewsOverview = styled.div``

const Post = styled(motion.div)``

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`

const ImgContainer = styled.div`
  height: 200px;
  width: 300px;
`

const PostContent = styled(motion.div)`
  width: 300px;
  border-radius: 20px;
  padding: 30px;
`

const Description = styled.div`
  min-height: 90px;

  p {
    font-size: ${props => props.theme.font.size.s};
  }
`

const PostWrapper = styled.div`
  position: relative;
  top: -20px;
`

const InnerPost = styled.div``

const Button = styled(motion.button)`
  ${props => props.disabled && css`
    pointer-events: none;
    opacity: 0.1;
  `}

  ${props => props.back && css`
    transform: rotate(180deg);
  `}

  svg {
    width: 30px;
    height: 30px;
    /* path {
      fill: ${props => props.theme.color.face.border};
      stroke: ${props => props.theme.color.face.border};
    } */
  }
`

const NewsOverview = ({ home, className }) => {
  const {
    posts: {
      edges: posts
    }
  } = useStaticQuery(graphql`{
    posts: allWordpressPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          ...PostsPreviewFrag
        }
      }
    }
  }`)

  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])
  const [sliceVar, setSliceVar] = useState({begin: 0, end: 3})

  let postsList = []

  if (home) {
    postsList = posts.slice(sliceVar.begin, sliceVar.end)
  } else {
    postsList = posts
  }

  const updateSlice = (string) => {
    switch (string) {
      case 'forward':
        if (sliceVar.end < posts.length) {
          setSliceVar({begin: sliceVar.begin + 3, end: sliceVar.end + 3})
        }
        break
      case 'back':
        if (sliceVar.begin !== 0) {
          setSliceVar({begin: sliceVar.begin - 3, end: sliceVar.end - 3})
        }
        break
      default:
        break
    }
  }

  return (
    <StyledNewsOverview className={`container ${className ? `${className}` : ``}`}>
      {home && (
        <div className="d-flex pb-5 pb-lg-4 px-4 justify-content-between align-items-center">
          <h2 className="font-weight-xl">Nieuws</h2>
          <div>

            <Button
              back 
              disabled={sliceVar.end < posts.length} 
              type="button" 
              onClick={() => updateSlice('back')}
              initial={{}}
              whileHover={{ rotate: [180, 160, 200, 180] }}
              transition={{ duration: 0.3 }}
            >
              <ArrowButton />
            </Button>
            <Button 
              disabled={sliceVar.begin !== 0} 
              type="button" 
              onClick={() => updateSlice('forward')}
              initial={{}}
              whileHover={{ rotate: [0, -20, 20, 0] }}
              transition={{ duration: 0.3 }}
            >
              <ArrowButton />
            </Button>
          </div>
        </div>
        
      )}
      <div ref={ref} className="row">
        {postsList.map(({ node: post }, index) => (
          <Post 
            key={post.wordpress_id} 
            className="col-lg-4 pb-5 d-flex justify-content-center"
            whileHover="hover"
            initial="init"
            // animate={isVisible || view ? "init" : "rest"}
            variants={{
              hover: {},
              rest: {
                opacity: 0.4, y: 30
              },
              init: {
                opacity: 1, y: 0,
                transition: { delay: index * 0.2, type: 'spring', duration: .8 }
              }
            }}
          >
            <InnerPost>
              <div className="d-flex justify-content-center">
                <ImgContainer>
                  <StyledImg loading="eager" fadeIn={false} fluid={post.acf.preview.image.localFile.childImageSharp.fluid} alt="" />
                </ImgContainer>
              </div>
              <PostWrapper className="d-flex justify-content-center">
                <PostContent
                  variants={{
                    rest: { backgroundColor: 'transparent', },
                    hover: {
                      boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)',
                      backgroundColor: '#ffffff',
                      transition: {
                        duration: 0.15,
                        type: 'spring'
                      }
                    }
                  }}
                >
                  <div className="d-flex flex-wrap">
                    {post.categories.map(category => (
                      <p className="font-size-xs mb-0 color-secondary mr-3 font-weight-xl" key={category.wordpress_id}>{category.name}</p>
                    ))}
                  </div>
                  <div className="d-flex align-items-center pt-2">
                    <p className="mb-0 mr-3 font-size-xs color-soft">
                      <Calendar className="mr-1" />
                      {post.acf.preview.date}
                    </p>
                    <p className="mb-0 font-size-xs color-soft">
                      <Eyeglasses className="mr-1" />
                      {post.acf.preview.readtime}
                    </p>
                  </div>
                  <div className="pt-3">
                    <h2 className="font-weight-xl font-size-ms">{parse(post.title)}</h2>
                    <Description>
                      {parse(post.acf.preview.small_description)}
                    </Description>
                  </div>
                  <div className="d-flex pt-2 justify-content-end">
                    <ButtonMinimal to={post.path} icon="arrow">
                      <p>Lees verder</p>
                    </ButtonMinimal>
                  </div>
                </PostContent>
              </PostWrapper>
            </InnerPost>
          </Post>
        ))}
      </div>
    </StyledNewsOverview>
  )
}

export default NewsOverview